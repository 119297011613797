import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './App.css';

function App() {
  return (<Navbar bg="light" expand="lg">
    <Navbar.Brand href="/">Swabi Referrals</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        {/* <Nav.Link href="/share">Share</Nav.Link> */}
        <Nav.Link href="/term">Terms of Use</Nav.Link>
        <Nav.Link href="/privacy">Privacy Policy</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>);
}

export default App;