import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { isAndroid, isIOS } from 'react-device-detect';

function Install(props) {
  const androidLink = "https://play.google.com/store/apps/details?id=com.swabifamily";
  const iosLink = "https://apps.apple.com/app/id1487271299";

  useEffect(() => {
    if (isAndroid) {
      window.open(androidLink, '_self');
    } else if (isIOS) {
      window.open(iosLink, '_self');
    } else {
      alert('App not support for your device');
    }
  }, [])

  return (
    <div className="main-container">
    </div>
  );
}

export default withRouter(Install);