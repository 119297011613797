import React from 'react';
import { withRouter } from 'react-router-dom';
import './Term.css';

function Term(props) {
  return (
    <div className="main-container">
        <div className="header"><b>Terms of Use</b></div>
        <span className="summary">By using the Swabi platform, you are agreeing to these terms. Any work received from a business on the Swabi platform has no responsibility from or liability by Swabi Mobile Inc. All work done is the responsibility and has liability by the business providing the actual work.
        <br/><br/>The Swabi Referrals platform allows you to reward referrers with a fee for completed proposals as a result of their referrals. The referral fee is set by the business and the business will incur a Swabi Fee of 15% additionally on the referral fee paid. This additional fee is made to Swabi Mobile Inc. For users who pay no referral fee for successful referrals, there is no Swabi fee.</span>
    </div>
  );
}

export default withRouter(Term);