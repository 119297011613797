import React from 'react';
import { withRouter } from 'react-router-dom';
import './Privacy.css';

function Privacy(props) {
  return (
    <div className="main-container">
        <div className="header"><b>Privacy Policy</b></div>
        <span className="summary">All data is private and secure unless you make the following actions. If you share business information or information for a project, then that data is now accessible by third parties within Swabi Referrals. If you make a referral, then that data is accessible by the referred party. All communication between two individuals or businesses on the platform is private to those involved parties.</span>
    </div>
  );
}

export default withRouter(Privacy);