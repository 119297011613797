import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './App';
//import Share from './components/Share';
import Privacy from './components/Privacy';
import Term from './components/Term';
import Install from './components/Install';

ReactDOM.render(
    <Router>
        <div>
            <App />
            <Switch>
                <Route exact path="/">
                    <Term />
                </Route>
                {/* <Route path="/share">
                    <Share />
                </Route> */}
                <Route path="/term">
                    <Term />
                </Route>
                <Route path="/privacy">
                    <Privacy />
                </Route>
                {/* <Route path="/install">
                    <Install />
                </Route> */}
            </Switch>
        </div>
    </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
